<template>
    <div>
        <div class="main">
            <CommonHeader></CommonHeader>
            <div class="content">
                <div class="left">
                   <span>白天在键盘前拼了命敲打代码，晚上则是个喜欢和朋友或者家人聊天谈心的普通人
对技术有狂热的爱好，也许有点宅，但绝不会是那种只会对着屏幕发呆的呆子。我也关心生活，虽然我不是大厨，
但我至少能保证做一顿还算过得去的晚餐。
<br>关于开发阿狸工具客户端的初衷，主要目的是为了解决工作中的碰到的一些问题，同时作为后端出身的我，也可以保持对前端的学习和自身状态，其次阿狸工具也是我长久以来的一个想法，趁着闲暇之余，
也算是一点点的将雏形给完成了，并开发完成了第一个批量贴图效率工具。</span>   
                </div>

                <div class="right">
                    <img src="../assets//images/my-wechat.jpg" alt="">
                    <span>我的微信</span>
                </div>
            </div>
            <div class="github">
                <a href="https://github.com/cmxStarCoding?tab=repositories" target="_blank">Github个人主页</a>
            </div>
        </div>

        <CommonBottom></CommonBottom>
    </div>
</template>

<script>
import CommonHeader from '../components/CommonHeader.vue'
import CommonBottom from '../components/CommonBottom.vue'


export default {
    name: 'AboutMeComponent',
    components: {
        CommonHeader,
        CommonBottom
    },
}
</script>

<style lang="scss" scoped>
.main {
    height: calc(100vh - 60px);
    width: 100%;
    background-image: url("../assets/images/background.webp");
    background-size: cover;
}
.content{
    display: flex;
    width: 100%;
    margin-top: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .left{
        width:55%;
        display: flex;
        justify-content: center;
        // text-indent: 4ch;
        margin-bottom: 80px;
        font-size: 16px;
        line-height: 30px;
    }
    .right{
        display: flex;
        width: 100%;
        flex-direction: column;
        align-items: center;
        span{
            margin: 10px;
            font-weight: bold;
        }
        img{
            width: 250px;
            height: auto;
            border-radius: 15px;
        }
    }

}
.github{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    a{
        color: white;
    }
}
</style>
