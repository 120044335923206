<template>
    <div>
        <div class="main">
            <CommonHeader></CommonHeader>
            <div class="content">
                <div class="left">
                    <span>阿狸工具的开发工作目前还是我一个人，
                        谈不上什么孤军奋战，因为目前的我做的并不是很多，随着第一个效率工具的开发完成，我更期待有更多志同道合的朋友可以加入进来开发出更多效率工具。
                    <br>我对阿狸工具的期待是它可以切实的为大家的工作提高效率，即使目前它并不完美，如果你有好的想法，可以通过邮箱(15638276200@163.com)或者添加我的微信反馈给我，如果你是一位开发工程师，也想加入阿狸工具的开发工作，可以通过微信联系我，介于阿狸工具选用的技术栈问题，加入开发工作前，需要对Golang、Gin框架、Electron、Vue3、Python、Javascript等知识具备一定了解
                    </span>
                </div>
            </div>
            <!-- <div class="github">
                <a href="https://www.baidu.com" target="_blank">Github个人主页</a>
            </div> -->
        </div>

        <CommonBottom></CommonBottom>
    </div>
</template>

<script>
import CommonHeader from '../components/CommonHeader.vue'
import CommonBottom from '../components/CommonBottom.vue'


export default {
    name: 'AboutMeComponent',
    components: {
        CommonHeader,
        CommonBottom
    },
}
</script>

<style lang="scss" scoped>
.main {
    height: calc(100vh - 60px);
    width: 100%;
    background-image: url("../assets/images/background.webp");
    background-size: cover;
}
.content{
    display: flex;
    width: 100%;
    margin-top: 160px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .left{
        width:55%;
        display: flex;
        justify-content: center;
        // text-indent: 4ch;
        margin-bottom: 80px;
        font-size: 16px;
        line-height: 30px;
    }
}
</style>
