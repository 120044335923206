<template>
    <div class="container">
        <span>@{{ year }} cmxstar 版权专属 请勿侵扰</span>
        <span class="split_up">|</span> 
        <span>豫ICP备2024043585号</span>
    </div>
</template>

<script>
export default {
    data() {
        return {
            year: ""
        }
    },
    created() {
        let date = new Date()
        this.year = date.getFullYear()
        console.log(this.year)
    },
}
</script >

<style lang="scss" scoped>
.container {
    display: flex;
    width: 100%;
    font-weight: bold;
    justify-content: center;
    // flex-direction: column;
    align-items: center;
    height: 60px;
    span{
        margin-top: 7px;
    }
    background-color: #161616;
    .split_up{
        margin-left:10px;
        margin-right:10px;

    }
}
</style>