<template>
    <div class="container">
        <div class="left" @click="this.$router.push({path:'/home'})">
            <img src="../assets/images/70_70.png" alt="">
            <span>阿狸工具</span>
        </div>
        <ul>
            <li @click="this.$router.push({path:'/home'})" :class="{'active':$route.path == '/home'}" >首页</li>
            <li @click="this.$router.push({path:'/about_me'})" :class="{'active':$route.path == '/about_me'}">关于我</li>
            <li @click="this.$router.push({path:'/join_alitools'})" :class="{'active':$route.path == '/join_alitools'}">加入开发</li>
        </ul>
    </div>
</template>

<script>
    export default{

        mounted() {
            // console.log(this.$route)
        },
    }
</script >

<style lang="scss" scoped>
    .container{
        display: flex;
        width: 100%;
        height: 60px;
        color: white;
        font-weight: bold;
    }
    .left{
        display: flex;
        width: 20%;
        justify-content: flex-end;
        align-items: center;
        img{
            width: 40px;
            height: auto;
            margin-right: 5px;
            cursor: pointer;
        }
        span{
            cursor: pointer;
        }
    }

    ul{
        display: flex;
        width: 60%;
        list-style-type: none;
        justify-content: flex-end;
        padding: 0px;
        li{
            margin-right: 10px;
            cursor: pointer;
        }
    }
    .active{
        border-bottom: 1px solid white;
    }
</style>