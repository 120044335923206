<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>

export default {
    name: 'App',
    components: {

    },
}
</script>

<style lang="scss" scoped>


</style>
