<template>
    <div>
        <div class="main">
            <CommonHeader></CommonHeader>
            <div class="main_top">
                <span class="app_name">
                    阿狸工具 为办公提效
                </span>
                <span class="current_year">
                    {{ current_year }}
                </span>
                <div class="download_area">
                    <div class="windows" @click="download('win')">
                        <svg t="1707038556787" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="13783" width="150" height="150"><path d="M437 567l0 325.5-341-47 0-278.5 341 0zm0-371.5l0 329.5-341 0 0-282.5zm491 371.5l0 393-453.5-62.5 0-330.5 453.5 0zm0-439l0 397-453.5 0 0-334.5z" p-id="13784" fill="#FFFFFF"></path></svg>
                        <span>Windows PC版本下载</span>
                    </div>
                    <div class="mac" @click="download('mac')">
                        <svg t="1707037722486" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="12715" width="150" height="150"><path d="M849.124134 704.896288c-1.040702 3.157923-17.300015 59.872622-57.250912 118.190843-34.577516 50.305733-70.331835 101.018741-126.801964 101.909018-55.532781 0.976234-73.303516-33.134655-136.707568-33.134655-63.323211 0-83.23061 32.244378-135.712915 34.110889-54.254671 2.220574-96.003518-54.951543-130.712017-105.011682-70.934562-102.549607-125.552507-290.600541-52.30118-416.625816 36.040844-63.055105 100.821243-103.135962 171.364903-104.230899 53.160757-1.004887 103.739712 36.012192 136.028093 36.012192 33.171494 0 94.357018-44.791136 158.90615-38.089503 27.02654 1.151219 102.622262 11.298324 151.328567 81.891102-3.832282 2.607384-90.452081 53.724599-89.487104 157.76107C739.079832 663.275355 847.952448 704.467523 849.124134 704.896288M633.69669 230.749408c29.107945-35.506678 48.235584-84.314291 43.202964-132.785236-41.560558 1.630127-92.196819 27.600615-122.291231 62.896492-26.609031 30.794353-50.062186 80.362282-43.521213 128.270409C557.264926 291.935955 604.745311 264.949324 633.69669 230.749408" p-id="12716" fill="#FFFFFF"></path></svg>
                        <span>MacOs 下载</span>
                    </div>
                    
                </div>
                <span class="mac_tip">ps：由于签名问题，Macos下载后需要在设置->安全性隐私中打开允许安装未知来源的App</span>

                <div class="run_env">

                    <span>Window运行环境：Windows10或者以上</span>
                    <span>MacOS运行环境：OS X10.11或者以上</span>
                </div>
                <div class="current_version">
                    <span>最新版 阿狸工具v{{current_version_info.version}}</span>
                </div>
            </div>
        </div>
        <div class="main_middle">
            <h2>贴图工具 解决同一张底图需大量更换二维码难题</h2>
            <img src="../assets/images/stick.jpg" alt="">
        </div>
        <CommonBottom></CommonBottom>
    </div>
</template>

<script>
import CommonHeader from '../components/CommonHeader.vue'
import CommonBottom from '../components/CommonBottom.vue'
import apiService from '../models/axios'

export default {
    name: 'HomeComponent',
    components: {
        CommonHeader,
        CommonBottom
    },
    data() {
        return {
            current_year:"",
            current_version_info:{}
        }
    },
    created(){
        let date = new Date()
        this.current_year = date.getFullYear()
        apiService.GetCurrentLatestVersion({}).then((response)=>{
            this.current_version_info = response.data
        }).catch(()=>{

        })
    },
    methods:{
         download(platform){
            console.log(platform)
            if(platform == 'win'){
                location.href = this.current_version_info.window_download_url
            }else{
                location.href = this.current_version_info.mac_download_url
            }

        }
    }
}
</script>

<style lang="scss" scoped>
.main{
    height: 100vh;
    width: 100%;
    background-image: url("../assets/images/background.webp");
    background-size: cover; 
}
.main_top{
    display: flex;
    width: 100%;
    height: 90%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:white;
    font-weight: bold;
    .app_name{
        font-size: 40px;
    }
    .current_year{
        font-size: 40px;
        margin-top: 20px;
    }
    .download_area{
        display: flex;
        flex-direction: column;
        div{
            color: white;
            font-weight: bold;
            margin-top: 40px;
            background-color:#FB4B59;
            border: none;
            padding:25px 80px;
            border-radius: 15px;
            cursor: pointer;
        }
        .icon{
            width: 20px;
            height: 20px;
        }
        .windows{
            display: flex;
            align-items: center;
            span{
                margin-left: 10px;
            }
        }
        .mac{
            display: flex;
            align-items: center;
            span{
                margin-left: 10px;
            }
        }
    }
    .run_env{
        margin-top: 50px;
        display: flex;
        flex-direction: column;
        span{
            margin-top: 10px;
        }
    }
    .current_version{
        margin-top: 10px;
    }
}
.mac_tip{
    margin-top: 100px;
}
.main_middle{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: auto;
    background-color:#161616;
    h2{
        margin-top: 50px;

    }
    img{
        width: 70%;
        border-radius: 5px;
        margin: 30px 0px;
    }
}

</style>
