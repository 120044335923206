import { createRouter, createWebHashHistory } from 'vue-router'

import Home from "./views/Home.vue"
import AboutMe from "./views/AboutMe.vue"
import JoinAlitools from "./views/JoinAlitools.vue"


const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        { path: '/',redirect: "/home"},
        { path: '/home',component: Home, name: "home",meta: {title: '阿狸工具'}},
        { path: '/about_me',component: AboutMe, name: "/about_me",meta: {title: '阿狸工具-关于我'}},
        { path: '/join_alitools',component: JoinAlitools, name: "/join_alitools",meta: {title: '阿狸工具-加入开发'}},
    ],
})

// 全局前置守卫 可用来判断一些路由是否用户是否可以访问等
router.beforeEach((to, from, next) => {
    document.title = to.meta.title ? to.meta.title : '阿狸工具';
    const bool = true;
    if (!bool) {
        next({ path: '/user_login' });
    } else {
        next();
    }
});

export default router