import { createApp } from 'vue'
import App from './App.vue'

import router from './router'
const app = createApp(App)
import './assets/css/global.css'; // 引入全局样式

//挂载路由
app.use(router)

app.mount('#app')
